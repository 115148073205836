import items from './items';
import widgetInfo from './widgetInfo';
import linkHref from './linkHref';

const root = {
	items,
	widgetInfo,
	linkHref
};

export default root;
