import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, useRequestData, usePrefs } from 'wsm-common-data';

import { getClassesForItem } from '../utils/getClassesForItem';

const WhyBuyItem = ({ totalCount, count, item: { title, iconId } }) => {
	const labels = useLabels();
	const { deviceType } = useRequestData();
	const { showCtaBelowItems, iconColor, borderColor } = usePrefs();
	const isMobile = deviceType !== 'DESKTOP';
	const classesforItems = getClassesForItem({
		count,
		isMobile,
		totalCount,
		showCtaBelowItems: showCtaBelowItems === 'true'
	});

	return (
		<div
			className={setClassNames([
				'why-buy-item',
				'd-flex',
				'flex-column',
				'px-1',
				'px-md-6',
				'px-sm-4',
				borderColor,
				...classesforItems
			])}
			data-testid="why-buy-item"
		>
			<i
				className={setClassNames([
					'ddc-icon',
					`ddc-icon-${iconId}`,
					'align-self-center',
					'mt-7',
					'mb-3',
					iconColor
				])}
			/>
			<p
				style={{ overflowWrap: 'break-word' }}
				className="text-center description px-4 px-sm-0 px-md-0 mb-6"
			>
				{labels.get(title)}
			</p>
		</div>
	);
};

WhyBuyItem.propTypes = {
	totalCount: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	item: PropTypes.shape({
		iconId: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired
	}).isRequired
};

export default WhyBuyItem;
