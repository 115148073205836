import { SET_LINK_HREF } from '../actions/types';

export default function linkHref(state = {}, action) {
	switch (action.type) {
		case SET_LINK_HREF:
			return action.payload;
		default:
			return state;
	}
}
