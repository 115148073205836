export const getClassesForLink = ({
	isMobile,
	totalCount,
	showCtaBelowItems = false
}) => {
	const isTotalCountEven = totalCount % 2 === 0;
	if (isMobile) {
		return isTotalCountEven ? 'col-xs-12 mt-5 mb-3' : 'col-xs-6 px-4';
	} else {
		if (isTotalCountEven || showCtaBelowItems) {
			return 'col-md-12 mt-6';
		}
		const grids = !((totalCount + 1) % 4 === 0) ? 4 : 3;
		return `col-md-${grids} col-sm-6 col-xs-6 px-1 px-md-6 px-sm-4`;
	}
};
