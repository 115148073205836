import React from 'react';
import PropTypes from 'prop-types';

import WhyBuyItem from './WhyBuyItem';
import MoreAboutDealer from './MoreAboutDealer';

const WhyBuyItems = ({ items }) => {
	return (
		<div
			className="why-buy-items d-flex flex-wrap px-6 pb-6 pt-3"
			data-testid="why-buy-items"
		>
			{React.Children.toArray(
				items.map((item, index) => (
					<WhyBuyItem
						item={item}
						count={index + 1}
						totalCount={items.length}
					/>
				))
			)}
			<MoreAboutDealer totalCount={items.length} />
		</div>
	);
};

WhyBuyItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default WhyBuyItems;
