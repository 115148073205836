import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import {
	usePrefs,
	useLabels,
	useSitemap,
	useRequestData
} from 'wsm-common-data';

import { setUrl } from '../utils/linkHelper';
import { getHtmlWithDLData } from '../utils/labelHelper';
import { getClassesForLink } from '../utils/getClassesForLink';

const MoreAboutDealer = ({ totalCount }) => {
	const labels = useLabels();
	const sitemap = useSitemap();
	const { deviceType } = useRequestData();
	const { showCtaBelowItems, ctaLink, ctaStyle, ctaClasses, ctaLabel } =
		usePrefs();

	const isMobile = deviceType !== 'DESKTOP';
	const linkHref = setUrl(sitemap, (ctaLink || '').trim());
	const classesforLink = getClassesForLink({
		isMobile,
		totalCount,
		showCtaBelowItems: showCtaBelowItems === 'true'
	});

	const containerClassNames = setClassNames([
		'why-buy-about-link',
		'd-flex',
		'flex-column',
		'col-xs-6',
		classesforLink
	]);

	const labelClassNames = setClassNames([
		`btn btn-${ctaStyle}`,
		ctaClasses,
		'text-center',
		'my-auto',
		'ddc-font-size-small'
	]);

	return (
		<div className={containerClassNames} data-testid="more-about-dealer-id">
			<a className={labelClassNames} href={linkHref}>
				{getHtmlWithDLData(labels.get(ctaLabel))}
			</a>
		</div>
	);
};

MoreAboutDealer.propTypes = {
	totalCount: PropTypes.number.isRequired
};

export default MoreAboutDealer;
