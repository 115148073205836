const TOTAL_COLUMNS = 12;
const COLUMNS = 2;
const BORDER_BOTTOM = 'border-bottom';
const BORDER_RIGHT = 'border-right';

export const getClassesForItem = ({
	count,
	isMobile,
	totalCount,
	showCtaBelowItems = false
}) => {
	const isTotalCountEven = totalCount % 2 === 0;

	// Set border classes on the item based on its position in the grid
	const borderClasses = [];
	let numItemsInBottomRow = isTotalCountEven ? COLUMNS : COLUMNS - 1;
	let isBottomRow = count > totalCount - numItemsInBottomRow;
	let isRightColumn = count % COLUMNS === 0;

	if (!isBottomRow) {
		borderClasses.push(BORDER_BOTTOM);
	}

	if (!isRightColumn) {
		borderClasses.push(BORDER_RIGHT);
	}

	const layoutClasses = ['col-xs-6', 'col-sm-6'];

	if (!isMobile) {
		const MAX_COLUMNS = 4;
		const MAX_ROWS = 2;

		// If totalCount is 4 or less, then the items will be displayed in a single row above 992px.
		const isSingleRow = totalCount <= MAX_COLUMNS;
		const isLastItem = count === totalCount;
		const numItemsInTopRow = isSingleRow
			? totalCount
			: Math.ceil(totalCount / MAX_ROWS);

		// Calculate the number of items in the bottom row (if the items are not all in a single row).
		numItemsInBottomRow = totalCount - numItemsInTopRow;
		isBottomRow = count > totalCount - numItemsInBottomRow;
		isRightColumn =
			(isTotalCountEven && (count === numItemsInTopRow || isLastItem)) ||
			(!isTotalCountEven &&
				((count === numItemsInTopRow && !isSingleRow) ||
					(isLastItem && showCtaBelowItems)));

		if (
			(isBottomRow || isSingleRow) &&
			borderClasses.includes(BORDER_BOTTOM)
		) {
			borderClasses.push('border-lg-bottom-0');
		}

		if (isRightColumn && borderClasses.includes(BORDER_RIGHT)) {
			borderClasses.push('border-lg-right-0');
		} else if (!isRightColumn && !borderClasses.includes(BORDER_RIGHT)) {
			borderClasses.push('border-lg-right');
		}

		// If totalCount is less than 4 and the CTA is displayed inline with the items, then add a column for the CTA.
		const numColumnsInTopRow =
			totalCount < MAX_COLUMNS && !showCtaBelowItems
				? totalCount + 1
				: numItemsInTopRow;

		// If totalCount is odd and the items will wrap to a second row (i.e., totalCount is 5 or 7) and showCtaBelowItems is true,
		// then the second row will have a different number of columns than the first row.
		if (
			!isTotalCountEven &&
			!isSingleRow &&
			showCtaBelowItems &&
			count > numColumnsInTopRow
		) {
			layoutClasses.push(`col-md-${TOTAL_COLUMNS / numItemsInBottomRow}`);
		} else {
			layoutClasses.push(`col-md-${TOTAL_COLUMNS / numColumnsInTopRow}`);
		}
	}

	return [...borderClasses, ...layoutClasses];
};
